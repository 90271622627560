import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = {
    delay: Number
  }

  connect() {
    // Store the original delay to restore it later
    this.originalDelay = 200
  }

  initialize() {
    // Bind the click method to this instance
    this.click = this.click.bind(this)
  }

  click(event) {
    if (this.hasDelayValue) {
      // Set the new delay
      Turbo.setProgressBarDelay(this.delayValue)

      // Define a one-time handler to restore the original delay after navigation
      const restoreDelay = () => {
        Turbo.setProgressBarDelay(this.originalDelay)
        // Remove the event listeners after restoring
        document.removeEventListener("turbo:render", restoreDelay)
        document.removeEventListener("turbo:frame-render", restoreDelay)
      }

      // Attach the one-time event listeners
      document.addEventListener("turbo:render", restoreDelay, { once: true })
      document.addEventListener("turbo:frame-render", restoreDelay, { once: true })
    }
  }
}
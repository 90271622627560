import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="send-message"
export default class extends Controller {
  static targets = ["textarea", "button", "save"];

  connect() {
    console.log("SendMessage controller connected");
  }

  send(event) {
    event.preventDefault();

    // Prevent sending empty messages
    const message = this.textareaTarget.value.trim();
    if (message === "") {
      return;
    }

    // Submit the form
    this.element.requestSubmit();

    // Handle post-send UI updates
    this.clearText();
  }

  save = this.debounce((event) => {
    event.preventDefault();

    // Simulate save operation
    this.saveTarget.click();
    this.saveTarget.textContent = "Saving...";
    setTimeout(() => {
      this.saveTarget.textContent = "Saved";
    }, 1500); // Adjust save delay as needed
  }, 2000); // Add debounce to prevent frequent saves

  // Utility method for clearing textarea and updating button state
  clearText(event) {
    event.preventDefault();
    this.textareaTarget.value = "";
    this.textareaTarget.focus();

    this.buttonTarget.disabled = true;
    setTimeout(() => {
      this.buttonTarget.disabled = false;
    }, 1500);
  }

  // Utility method for debouncing
  debounce(func, wait) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }
}
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar-scroll-position"
export default class extends Controller {
  connect() {
    const scrollTop = localStorage.getItem("sidebarScrollPosition");

    if (scrollTop) {
      this.element.style.overflow = "hidden"; // 临时隐藏滚动条
      this.element.scrollTop = scrollTop;

      // 短暂延迟后恢复滚动条
      setTimeout(() => {
        this.element.style.overflow = "auto";
      }, 50);
    }
  }

  scroll() {
    localStorage.setItem("sidebarScrollPosition", this.element.scrollTop);
  }
}

// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import "@rails/request.js";

import "trix";
import "@rails/actiontext";
// import "./blurhash";
// import 'lightgallery/css/lightgallery.css'

document.addEventListener("turbo:load", () => {
    let metaTag = document.querySelector("meta[name='google-analytics-id']");
    if (metaTag && metaTag.content) {
        let googleId = metaTag.content;
        let gtag = function() {
            window.dataLayer.push(arguments);
        };
        window.dataLayer = window.dataLayer || [];
        gtag("js", /* @__PURE__ */ new Date());
        gtag("config", googleId);
    }
});

// 注册 Service Worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
            .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);
            }, (err) => {
                console.error('Service Worker registration failed:', err);
            });
    });
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const token = this.getCookie("token");
    console.log("Get Token:", token)
    if (token) {
      const extensionId = "fommaanbdigchmhmniejidfbdjjapccg"; // 确保这里是您的扩展ID
      chrome.runtime.sendMessage(extensionId, {
        type: "AUTH_TOKEN",
        token: token
      }, (response) => {
        console.log("Token Response:", response);
        this.deleteCookie("token");  // 删除 token cookie
      });
    }
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  deleteCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}